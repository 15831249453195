var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('footerEn')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',[_c('img',{attrs:{"onclick":"window.history.go(-1)","src":require("../../assets/img/go.png"),"alt":""}}),_c('span',{staticClass:"fs",staticStyle:{"padding-left":"33%"}},[_vm._v("关于我们")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"photo_img"},[_c('p',{staticClass:"fb"},[_vm._v("关于我们")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_c('div',{staticClass:"about_content"},[_c('p',{staticClass:"firm fz"},[_vm._v("企业介绍")]),_c('p',{staticClass:"introduction fs"},[_vm._v("Enterprise introduction")]),_c('p',{staticClass:"about_text fs"},[_vm._v("北京再佳学教育科技有限公司（以下简称再佳学）是美育融合化在线教育综合服务提供商，历时五年对基础进行二次开发，将传统艺术、现代艺术、非遗、儿童舞台剧等两百多种艺术表现形式融入，让传统的课本知识活起来，提高学生学习兴趣，同时融合人工智能和大数据分析赋能传统课本。公司核心团队由多位教育行业、微软、苹果、京东等互联网领域资深人士组成。")]),_c('p',{staticClass:"about_text fs"},[_vm._v("再佳学与中宣部学习强国、人民日报人民网、人民日报出版社、教育部人民教育数字出版社、中央电视台央视频、北京师范大学、人民周刊、人民数据签署合作协议。")]),_c('div',{staticClass:"vision"},[_c('p',{staticClass:"vision_text fz"},[_vm._v("愿景和使命")]),_c('p',{staticClass:"mission fs"},[_vm._v("Vision and Mission")]),_c('p',{staticClass:"vision_title fs"},[_vm._v("愿景：促进国内教育均衡，助力中华文化走出去")]),_c('p',{staticClass:"vision_title fs"},[_vm._v("使命：让每一位孩子拥有快乐的童年")]),_c('p',{staticClass:"vision_title fs"},[_vm._v("价值观：快乐、尚美、融合、创新")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product"},[_c('div',{staticClass:"product_content clearfix"},[_c('p',{staticClass:"product_title fz"},[_vm._v("主推产品")]),_c('p',{staticClass:"main fb"},[_vm._v("main products")]),_c('p',{staticClass:"product_info fb"},[_vm._v("最美课本：美育融合化学习平台")]),_c('div',{staticClass:"product_text"},[_c('p',[_vm._v(" 产品优势： "),_c('span',[_vm._v(" 美育融合、应景学习、双语配音、人工智能、同步 ")])]),_c('p',[_vm._v(" 学科分类: "),_c('span',[_vm._v(" 语文、数学、英语 ")])]),_c('p',[_vm._v(" 覆盖年级： "),_c('span',[_vm._v(" 1-6年级 ")])]),_c('p',[_vm._v(" 为学生带来的核心价值： "),_c('span',[_vm._v(" 提升学习兴趣、提高阅读能力、激发脑力思维、增强知识记忆、树立人格自信 ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"growth"},[_c('div',{staticClass:"growth_content"},[_c('span',{staticClass:"growth_photo"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"enterprise"},[_c('div',{staticClass:"enterprise_content"},[_c('p',{staticClass:"enterprise_title fb"},[_vm._v("企业场景")]),_c('p',{staticClass:"scenario fb"},[_vm._v("Enterprise scenario")]),_c('div',{staticClass:"enterprise_list"},[_c('ul',[_c('li',[_c('img',{attrs:{"src":require("../../assets/img/环境.png"),"alt":""}}),_c('p',{staticClass:"fs"},[_vm._v("办公环境1")])]),_c('li',[_c('img',{attrs:{"src":require("../../assets/img/环境s.png"),"alt":""}}),_c('p',{staticClass:"fs"},[_vm._v("办公环境2")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"us"},[_c('div',{staticClass:"us_content clearfix"},[_c('p',{staticClass:"us_title fz"},[_vm._v("联系我们")]),_c('p',{staticClass:"us_text fs"},[_vm._v("About us")]),_c('div',{staticClass:"us_right"},[_c('p',{staticClass:"fs"},[_vm._v(" 公司邮箱:zaijiaxue@zjx.com "),_c('br'),_vm._v(" 地址: 北京市朝阳区金台西路甲2号 ")])])])])
}]

export { render, staticRenderFns }